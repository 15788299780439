import React from 'react';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import get from 'lodash/get';
import { linkResolver } from 'gatsby-source-prismic-graphql';
import { RichText } from 'prismic-reactjs';

const IllustrationTemplate = (props) => {
  const title = props.data.prismic.allIllustrationss.edges[0].node.titre;
  const credits = props.data.prismic.allIllustrationss.edges[0].node.credits;
  const editeur = props.data.prismic.allIllustrationss.edges[0].node.editeur;
  const description = props.data.prismic.allIllustrationss.edges[0].node.description;

 
  const titleSeo = props.data.prismic.allIllustrationss.edges[0].node.title_seo;
  const descriptionSeo = props.data.prismic.allIllustrationss.edges[0].node.description_seo;
  
  const photos = get(props.data, 'prismic.allIllustrationss.edges[0].node.photos');
  const prev = get(props.data, 'prismic.prevIllustration');
  const next = get(props.data, 'prismic.nextIllustration');
 
 
  return (
    <Layout>
      <SEO 
        title={(titleSeo) ? titleSeo : ''}
        description={(descriptionSeo) ? descriptionSeo : ''}
      />
      <div className="l-illustration">
        <div className="container">
          <p className="m-return l-illustration__return">
            <Link to="/illustrations" className="m-return__link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
              </svg>
              Retour
            </Link>
          </p>
          <div className="l-illustration__header">
            <div className="l-illustration__header__title">{RichText.render(title)}</div>
            <div className="l-illustration__header__infos">
            
              {RichText.render(editeur)}
              {RichText.render(credits)}
            </div>
            <div className="l-illustration__header__text">
              {RichText.render(description)}
            </div>
          </div>
          <div className="l-illustration__photos">
          
            {photos.map( (photo,index) => {
              let photoSharp = get(photo, 'photoSharp.childImageSharp.fluid')
              return (
                <div key= {index} className="l-illustration__photos__item">
                  <Img fluid={photoSharp} />
                </div>
              )
            })}
           
            </div>
 
            <div className="m-pagination">
              <div className="m-pagination__wrapper">
                { prev !== null &&
                  <div className="m-pagination__item">
                      <Link to={linkResolver(prev._meta)} aria-label="Illustration précedente" className="m-pagination__item__link m-pagination__item__link--prev">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                        <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
                      </svg>
                        Précédent
                      </Link>
                  </div>
                  }
                { next !== null &&
                  <div className="m-pagination__item">
                    <Link to={linkResolver(next._meta)} aria-label="Illustration suivante" className="m-pagination__item__link m-pagination__item__link--next">
                        Suivant
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                          <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
                        </svg>
                    </Link>
                  </div>
                }
              </div>
            </div>
        </div>
      </div> 
      
    </Layout>  
  )
}
  
export default IllustrationTemplate


export const query = graphql`
  
  query Illustration(
    $uid: String!
    $paginationPreviousUid: String!
    $paginationNextUid: String!
    ) {
    prismic { 
      allIllustrationss(uid: $uid) {
        pageInfo { 
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            _meta {
              uid
            }
            title_seo
            description_seo
            titre
            editeur
            credits
            description 
            photos {
              photo
              photoSharp {
                childImageSharp {
                  fluid(maxWidth: 600, quality  :100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
          }
          cursor
        }
       
      }
      prevIllustration: illustrations(uid: $paginationPreviousUid, lang: "fr-fr") {
        titre
        _meta {
          uid
          lang
          type
        }
      }
      nextIllustration: illustrations(uid: $paginationNextUid, lang: "fr-fr") {
        titre
        _meta {
          uid
          lang
          type
        }
      }
    }
  }
`; 


